import * as React from "react";
import { Link } from "gatsby";
import { get } from "lodash";
import { Header, Container, Segment, Icon, Label, Button, Grid, Card, Image, Item, Comment } from "semantic-ui-react";
import { MarkdownRemark, ImageSharp, MarkdownRemarkConnection, Site } from "../generated/graphql-types";
import BlogTitle from "../components/BlogTitle";
import {withLayout, LayoutProps} from "../components/Layout";
import { graphql } from "gatsby";

interface BlogPostProps extends LayoutProps {
  data: {
    post: MarkdownRemark;
    recents: MarkdownRemarkConnection;
    site: Site
  };
}

const BlogPostPage = (props: BlogPostProps) => {
  const { frontmatter, html, timeToRead } = props.data.post;
  if (frontmatter === undefined || frontmatter === null) {
    throw new Error('Opss!');
  }
  const { author } = frontmatter;
  if (author === undefined || author === null) {
    throw new Error('Opss!');
  }
  const { avatar: avatarFile } = author;
  if (avatarFile === undefined || avatarFile === null) {
    throw new Error('Opss!');
  }
  const avatar = avatarFile.children[0] as ImageSharp;

  const tags: string[] = (frontmatter.tags !== undefined && frontmatter.tags !== null)?frontmatter.tags.filter((tag) => {tag !== undefined && tag !== null} ) as string[]:[] as string[];
  const tagElements = tags
    .map((tag) => <Label key={tag}><Link to={`/blog/tags/${tag}/`}>{tag}</Link></Label>);

  const recents = props.data.recents.edges
    .map(({ node }) => {
      const { frontmatter: curFrontmatter } = node;
      if (curFrontmatter === undefined || curFrontmatter === null) {
        throw new Error('Opss!');
      }
      const { author: curAuthor } = curFrontmatter;
      if (curAuthor === undefined || curAuthor === null) {
        throw new Error('Opss!');
      }
      const { avatar: curAvatarFile } = curAuthor;
      if (curAvatarFile === undefined || curAvatarFile === null) {
        throw new Error('Opss!');
      }
      const recentAvatar = curAvatarFile.children[0] as ImageSharp;
      if (recentAvatar === undefined || recentAvatar === null) {
        throw new Error('Opss!');
      }
      const { fixed: recentAvatarFixed } = recentAvatar;
      if (recentAvatarFixed === undefined || recentAvatarFixed === null) {
        throw new Error('Opss!');
      }
      const recentCover = get(node, "frontmatter.image.children.0.fixed", {});
      const extra = (
        <Comment.Group>
          <Comment>
            <Comment.Avatar
              src={recentAvatarFixed.src}
              srcSet={recentAvatarFixed.srcSet}
            />
            <Comment.Content>
              <Comment.Author style={{ fontWeight: 400 }}>
                {curAuthor.id}
              </Comment.Author>
              <Comment.Metadata style={{ margin: 0 }}>
                {node.timeToRead} min read
              </Comment.Metadata>
            </Comment.Content>
          </Comment>
        </Comment.Group>
      );
      const { fields: nodeFields } = node;
      if (nodeFields === undefined || nodeFields === null) {
        throw new Error('Opss!');
      }
      const { slug: nodeFieldsSlug } = nodeFields;
      if (nodeFieldsSlug === undefined || nodeFieldsSlug === null) {
        throw new Error('Opss!');
      }

      return (
        <div key={nodeFieldsSlug} style={{ paddingBottom: "1em" }}>
          <Card as={Link}
            to={nodeFieldsSlug}
            image={recentCover}
            header={curFrontmatter.title}
            extra={extra}
          />
        </div>
      );
    });

  const cover = get(frontmatter, "image.children.0.fixed", {} );
  const { fixed: avatarFixed } = avatar;
      if (avatarFixed === undefined || avatarFixed === null) {
        throw new Error('Opss!');
      }
  return (
    <Container>
      <BlogTitle />
      <Segment vertical style={{ border: "none" }}>
        <Item.Group>
          <Item>
            <Item.Image size="tiny"
              src={avatarFixed.src}
              srcSet={avatarFixed.srcSet}
              circular
            />
            <Item.Content>
              <Item.Description>{author.id}</Item.Description>
              <Item.Meta>{author.bio}</Item.Meta>
              <Item.Extra>{frontmatter.updatedDate} - {timeToRead} min read</Item.Extra>
            </Item.Content>
          </Item>
        </Item.Group>
        <Header as="h1">{frontmatter.title}</Header>
      </Segment>
      <Image
        {...cover}
        fluid
      />
      <Segment vertical
        style={{ border: "none" }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
      <Segment vertical>
        {tagElements}
      </Segment>
      {props.data.site
        && props.data.site.siteMetadata
      }
      <Segment vertical>
        <Grid padded centered>
          {recents}
        </Grid>
      </Segment>
    </Container>
  );
};

export default withLayout(BlogPostPage);

export const pageQuery = graphql`
  query TemplateBlogPost($slug: String!) {
  post: markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    excerpt
    timeToRead
    fields {
      slug
    }
    frontmatter {
      tags
      author {
        id
        bio
        twitter
        avatar {
          children {
            ... on ImageSharp {
              fixed(width: 80, height: 80, quality: 100) {
                src
                srcSet
              }
            }
          }
        }
      }
      title
      updatedDate(formatString: "MMM D, YYYY")
      image {
        children {
          ... on ImageSharp {
            fixed(width: 900, height: 300, quality: 100) {
              src
              srcSet
            }
          }
        }
      }
    }
  }
  recents: allMarkdownRemark(
    filter: {
      fields: {slug: {ne: $slug}}
      frontmatter: {draft: {ne: true}},
      fileAbsolutePath: {regex: "/blog/"},
    },
    sort: {order: DESC, fields: [frontmatter___updatedDate]},
    limit: 4
  ) {
    edges {
      node {
        fields {
          slug
        }
        timeToRead
        frontmatter {
          title
          image {
            children {
              ... on ImageSharp {
                fixed(width: 300, height: 100) {
                  src
                  srcSet
                }
              }
            }
          }
          author {
            id
            avatar {
              children {
                ... on ImageSharp {
                  fixed(width: 36, height: 36) {
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
